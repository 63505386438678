import React from "react";
import Image_Youtube from "../../assets/img_youtube.png";
import Image_Twitter from "../../assets/img_twitter.png";
import Image_Linktree from "../../assets/img_linktree.png";
import Image_Opensea from "../../assets/img_opensea.png";
import SpriteSheet_Discord from "../Spritesheets/Spritesheet_Discord";
import SpriteSheet_Instagram from "../Spritesheets/Spritesheet_Instagram";
import SpriteSheet_Youtube from "../Spritesheets/Spritesheet_Youtube";
import SpriteSheet_Twitter from "../Spritesheets/Spritesheet_Twitter";
import SpriteSheet_Linktree from "../Spritesheets/Spritesheet_Linktree";
import SpriteSheet_Opensea from "../Spritesheets/Spritesheet_Opensea";
import '../../styles/footer.scss';
import AudioPlayer from "../AudioPlayer";
import NewsletterSubscribe from "./NewsletterSubscribe";

const Footer = () => {
    return (
        <div>
            <hr className="footer-line" />
            <div className="footer">
                <div className="email-container">
                <NewsletterSubscribe/>
                </div>

                {/*<div>
                    <img class="img-fluid footer-logo" src="https://ik.imagekit.io/bayc/assets/bayc-footer.png" alt="logo" />
                    </div>*/}
                <div className="company-info">
                    <div className="socialmedialink-footer-container">
                        <div className="socialmedialink-footer">
                            <a href="https://twitter.com/TheOmniverse4" target="_blank"><img src={Image_Twitter}/>
                                <SpriteSheet_Twitter />
                            </a>
                        </div>
                        <div className="socialmedialink-footer">
                            <a href="https://www.youtube.com/channel/UCjWKnbfyW7s4CcQ1fHeXC7g" target="_blank"><img src={Image_Youtube}/>
                                <SpriteSheet_Youtube />
                            </a>
                        </div>
                        {/*<div className="socialmedialink-footer">
                            <a href="https://twitter.com/TheOmniverse4" target="_blank">
                                <SpriteSheet_Instagram />
                            </a>
                        </div>
                        <div className="socialmedialink-footer">
                            <a href="https://twitter.com/TheOmniverse4" target="_blank">
                                <SpriteSheet_Discord />
                            </a>
                        </div>*/}
                        <div className="socialmedialink-footer">
                            <a href="https://linktr.ee/theomniverse" target="_blank"><img src={Image_Linktree}/>
                                <SpriteSheet_Linktree />
                            </a>
                        </div>
                        <div className="socialmedialink-footer">
                            <a href="https://opensea.io/collection/laser-cats" target="_blank"><img src={Image_Opensea}/>
                                <SpriteSheet_Opensea />
                            </a>
                        </div>
                    </div>
                    <div>
                        <p className="copyright"><span className="copy-left">© 2023 Laser Cats</span></p>
                    </div>
                </div>
            </div>
            <AudioPlayer />
        </div>
    );
}

export default Footer;