import React, { Component } from 'react';
import Spritesheet from 'react-responsive-spritesheet';
import spritesheet from "../../assets/spritesheet_rollover_button_opensea.png"
 
class Spritesheet_Opensea extends Component {
 
  render() {
 
    return (
      <Spritesheet className='spritesheet-container'
      image={spritesheet}
      widthFrame={200}
        heightFrame={200}
        steps={1}
        fps={24}
        autoplay={false}
        loop={true}
        getInstance={spritesheet => {
            this.spriteInstance = spritesheet;
          }}
     />
    );
 }
 
}

export default Spritesheet_Opensea;