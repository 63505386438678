exports.songsdata = [
    {
        "title": "Omniverse",
        "url": "./assets/LC_Omniverse.mp3"
    },
    {
        "title": "Aris",
        "url": "./assets/LC_Aris_Theme.mp3"
    },
    {
        "title": "Nero",
        "url": "./assets/LC_Nero_Theme.wav"
    },
    {
        "title": "The Bounty",
        "url": "./assets/LC_The_Bounty_Theme.wav"
    }
]