import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom/client';
import Header from './Header';
import imageRoadmapBg from '../assets/roadmap_bg.jpg';
import imageRoadmapPlanets from '../assets/rm_04.png';
import imageRoadmapClouds1 from '../assets/rm_05.png';
import { anime } from 'react-anime';
import '../styles/roadmap.scss';

const Roadmap = () => {

    const [offsetY, setOffsetY] = useState(0);
    const [visible1, setVisible1] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const [visible3, setVisible3] = useState(false);
    const handleScroll = () => {
        setOffsetY(window.pageYOffset);
        SetVisible(document.getElementById("phase1box"));
        SetVisible(document.getElementById("phase2box"));
        SetVisible(document.getElementById("phase3box"));
    }

    function SetVisible(elem)
    {
        if (elem) {
            const elementPosition = elem.getBoundingClientRect().top;
            const screenPosition = window.innerHeight / 2;

            if (elementPosition < screenPosition) {
                if(elem.id == "phase1box")
                    setVisible1(true);
                else
                if(elem.id == "phase2box")
                    setVisible2(true);
                else
                if(elem.id == "phase3box")
                    setVisible3(true);
            }
        }
    }

    useEffect(() => {
        ReactDOM.createRoot(document.getElementById("imagestars1")).render(<StarrySky />);
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <div className = "roadmap-container">
            <Header />
            <div className = "roadmap">
              <div className = "roadmap-bg">
              <img src = {imageRoadmapBg} id = "roadmap-img" />
              
              <div id = "imagestars1" style={{ transform: `translateY(-${offsetY * 0}px)` }}></div>

              <div id = "imageplanets"
              style={{ transform: `translateY(-${offsetY * 0.4}px)` }}>
                  <img src = {imageRoadmapPlanets} id = "planets-img" />
              </div>

              <div id = "imageclouds1"
              style={{ transform: `translateY(-${offsetY * 0.7}px)` }}>
                  <img src = {imageRoadmapClouds1} id = "clouds-img" />
              </div>

              <div id = "phase1box"
              className={`fade-in ${visible1 ? "is-visible" : ""}`}
              style={{ opacity: visible1 ? 1 : 0 , transform: `translateY(-${offsetY * 0.4}px)` }}
              >
                <div id = "phase1content" className = "phasecontent">
                  <h1>Phase 1</h1>
                    <p>
                    •	Begin introducing Laser Cats and the vision of Omniverse to the world via twitter, YouTube, etc.<br/><br/>
                    •	Build a community around those who want to be a part of this mission.<br/><br/>
                    •	Launch a website, collaborate with other NFT projects, and creation of private discord for members of the community that want to be in the fold for the newest updates from the Omniverse team.<br/><br/>
                    •	Release trailers and giveaways on Twitter and discord all the way until mint day.<br/><br/>
                    •	Mint Genesis Laser Cats Collection.<br/><br/>
                    •	Reveal the collection within the next week of initial mint.<br/><br/>
                    •	Within 2 days of reveal get Mercenaries Section/Gallery live on Laser Cats.io<br/>
                    </p>
                </div>
              </div>

              <div id = "phase2box"
              className={`fade-in ${visible2 ? "is-visible" : ""}`}
              style={{ opacity: visible2 ? 1 : 0 , transform: `translateY(-${offsetY * 0.4}px)` }}
              >
                <div id = "phase2content" className = "phasecontent">
                  <h1>Phase 2</h1>
                  <p>
                  •	Tavern 77 Opening<br/><br/>
                  •	When Tavern 77 opens up all the Laser Cat Owners will be able to access it by signing into the crypto wallet that holds their Laser Cat NFT.<br/><br/>
                  •	This is where the Laser Cat holders will be able to receive air drops for other Omniverse NFTs in the future.<br/><br/>
                  •	Our goal for Tavern 77 is to become a community hub and forum where all Laser Cat owners can interact. It’ll also be the Headquarters for news and future developments by The Omniverse team.<br/><br/>
                  •	There will be an opportunity to vote in which the community can influence the unique path the Laser Cats and Omniverse takes.<br/><br/>
                  •	In the future we are looking at making tangible merchandise as well. What the products will be is undetermined at this time, but they would be available first to those in Tavern 77.<br/><br/>
                  •	Exclusive giveaways will be available only to those that have access to Tavern 77. One of our favorite ideas is winning the chance to be on the story board team with the creators and assist with an episode in the Laser Cats future series.<br/><br/><br/>

                  •	Live Events<br/><br/>
                  •	A live “Thank you” celebratory event will be thrown for the community if we are able to sell out the Laser Cats Collection.<br/>

                  </p>
                </div>
              </div>

              <div id = "phase3box"
              className={`fade-in ${visible3 ? "is-visible" : ""}`}
              style={{ opacity: visible3 ? 1 : 0 , transform: `translateY(-${offsetY * 0.4}px)` }}
              >
                <div id = "phase3content" className = "phasecontent">
                  <h1>Phase 3</h1>
                  <p>
                  •	By this juncture we are expecting to either be producing a series, working on game development, or working on some type of physical product to exist within The Omniverse. We will continue creating new content for our community and opportunities for others to join us on our Web3 journey.<br/><br/>
                  •	There will also be the potential to plug in IP and import voxels of your Laser Cats to other metaverse worlds.<br/><br/>
                  •	Dropping future collections and collaborations with other companies that exist within the Omniverse is something that we’d like to make a reality.<br/><br/>
                  •	Before the series is released, we would like to hold a Premier showing of the first episode. Ideally, all of the original community will be in attendance for this. This will mark the project coming full circle since it’s inception.<br/>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
}

// Twinkling Night Sky by Sharna

class StarrySky extends React.Component {
    state = {
      num: 700,
      vw: 2000, //Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
      vh: 3000
    };

    starryNight = () => {
      anime({
        targets: ["#sky .star"],
        opacity: [
          {
            duration: 700,
            value: "0"
          },
          {
            duration: 700,
            value: "1"
          }
        ],
        easing: "linear",
        loop: true,
        delay: (el, i) => 2 * i
      });
    };

    randomRadius = () => {
      return Math.random() * 1 + 0.5;
    };
    getRandomX = () => {
      return Math.floor(Math.random() * Math.floor(this.state.vw)).toString();
    };
    getRandomY = () => {
      return Math.floor(Math.random() * Math.floor(this.state.vh)).toString();
    };
    
    componentDidMount() {
      this.starryNight();
    }

    render() {
      const { num } = this.state;
      return (
        <div id="twinklingstars">
          <svg id="sky" viewBox = "0 0 1500 2500">
            {[...Array(num)].map((x, y) => (
              <circle
                cx={this.getRandomX()}
                cy={this.getRandomY()}
                r={this.randomRadius()}
                stroke="none"
                strokeWidth="0"
                fill="#CCCCCC"
                key={y}
                className="star"
              />
            ))}
          </svg>
        </div>
      );
    }
  }
  
export default Roadmap;