import React, {useEffect, useState} from 'react';
import Header from "./Header";
import '../styles/team.scss';
import cat1Video from '../assets/team_artdir.mp4';
import cat2Video from '../assets/team_techdir.mp4';
import cat3Video from '../assets/team_prod.mp4';
import cat4Video from '../assets/team_code.mp4';
import Loading from "./Loading";

const Team = () => {
    const [isLoadingComplete, setLoadingComplete] = useState(false);

    const SetComplete = () => {
        setLoadingComplete(true);
    }

    useEffect(() => {
        function handleContextMenu(e) {
          e.preventDefault(); // prevents the default right-click menu from appearing
        }
        // add the event listener to the component's root element
        const vidElements = document.getElementsByClassName('team-images');
        const vidElement = vidElements[0];
        vidElement.addEventListener('contextmenu', handleContextMenu);
        // remove the event listener when the component is unmounted
        return () => {
          vidElement.removeEventListener('contextmenu', handleContextMenu);
        };
      }, []);

    return (
        <div className = "team-container">
            <Header />
            <div className='spritesheet-loading-container'
            style={{visibility: isLoadingComplete ? "hidden" : "visible"}}
            >
                <Loading />
            </div>
            <div className = "team">
                <div className = "team-title">
                    <h1>
                        The Omniverse Four
                    </h1>
                    <h2>
                        The team at Omniverse is made up of artists, engineers, and creatives that have been working in the video game and film/animation industry for twenty plus years.
                    </h2>
                </div>
                <div className = "team-images">
                    <span className="mem-image">
                        <video
                            preload="none"
                            width = "100%"
                            style={{ display: "inline-block" }}
                            src={cat1Video}
                            autoPlay
                            muted
                            playsInline
                            type="video/mp4"
                            loop
                            onLoadedData={SetComplete}
                        />
                        <p>
                            <span className="mem-title">The One</span>
                            <br/>Art Director/Design Lead
                        </p>
                    </span>
                    <span className="mem-image">
                    <video
                            preload="none"
                            width = "100%"
                            style={{ display: "inline-block" }}
                            src={cat2Video}
                            autoPlay
                            muted
                            playsInline
                            type="video/mp4"
                            loop
                        />
                        <p>
                            <span className="mem-title">Number 2</span>
                            <br/>Second in Command - Technical Director
                        </p>
                    </span>
                    <span className="mem-image">
                        <video
                            preload="none"
                            width = "100%"
                            style={{ display: "inline-block" }}
                            src={cat3Video}
                            autoPlay
                            muted
                            playsInline
                            type="video/mp4"
                            loop
                        />
                        <p>
                            <span className="mem-title">Wolverine</span>
                            <br/>Night gamer - Lead Producer
                        </p>
                    </span>
                    <span className="mem-image">
                        <video
                            preload="none"
                            width = "100%"
                            style={{ display: "inline-block" }}
                            src={cat4Video}
                            autoPlay
                            muted
                            playsInline
                            type="video/mp4"
                            loop
                        />
                        <p>
                            <span className="mem-title">Fingers</span>
                            <br/>Makes money with keystrokes - Lead Engineer
                        </p>
                    </span>
                </div>
            </div>
        </div>
    );
}

export default Team;