import React, { useState } from "react";
import Header from "./Header";
import '../styles/about.scss';
import videoBoardIntro from "../assets/board_intro.mp4";
import videoBoardLoop from "../assets/board_loop.mp4";
import Spritesheet_About_LCC from './Spritesheets/Spritesheet_About_LCC';
import Spritesheet_About_Lore from './Spritesheets/Spritesheet_About_Lore';
import Spritesheet_About_OurStory from './Spritesheets/Spritesheet_About_OurStory';
import Spritesheet_About_OurMission from './Spritesheets/Spritesheet_About_OurMission';
import Spritesheet_About_Tavern from './Spritesheets/Spritesheet_About_Tavern';
import Spritesheet_About_Popup_LCC from './Spritesheets/Spritesheet_About_Popup_LCC';
import Spritesheet_About_Popup_Lore from './Spritesheets/Spritesheet_About_Popup_Lore';
import Spritesheet_About_Popup_OurMission from './Spritesheets/Spritesheet_About_Popup_OurMission';
import Spritesheet_About_Popup_OurStory from './Spritesheets/Spritesheet_About_Popup_OurStory';
import Spritesheet_About_Popup_Tavern from './Spritesheets/Spritesheet_About_Popup_Tavern';
import Loading from "./Loading";

const About = ({showInfoText}) => {
    const [videoIndex, setVideoIndex] = useState(0);
    const [popup, setPopup] = useState(false);
    const [popupHeaderText, setPopupHeaderText] = useState('temp popup header');
    const [popupText, setPopupText] = useState('temp popup info');
    const [isLoadingComplete, setLoadingComplete] = useState(false);

    const SetComplete = () => {
        setLoadingComplete(true);
    }
 
    function handlePopup(event, infoHeader, info){
        setPopupText(info);
        setPopupHeaderText(infoHeader);
        setPopup(infoHeader? true:false);
    }

    return (
        <div className = "about-container">
            <Header />
            <div className='spritesheet-loading-container'
            style={{visibility: isLoadingComplete ? "hidden" : "visible"}}
            >
                <Loading />
            </div>
            <div className = "about">
                <div className = "about-bg">
                    <div className="aboutvideo">
                        <video
                            preload="none"
                            width = "100%"
                            style={{ display: videoIndex === 1 ? "none" : "inline-block"}}
                            src={videoBoardIntro}
                            autoPlay
                            muted
                            playsInline
                            type="video/mp4"
                            onLoadedData={SetComplete}
                            onEnded={() => {
                                    setVideoIndex((idx) => idx + 1);
                                }
                            }
                        />
                        <video
                            preload="none"
                            width = "100%"
                            style={{ display: videoIndex === 0 ? "none" : "inline-block" }}
                            src={videoBoardLoop}
                            autoPlay
                            muted
                            playsInline
                            type="video/mp4"
                            loop
                        />
                    </div>

                    <button id = "lasercatscolbutton" onClick = {(event) => handlePopup(event, "Laser Cats Collection",
                        "Laser Cats are a collection of programmatically randomly generated NFTs on the Ethereum block chain. The Laser Cats collection will consist of 5,000 cats that have an extensive pool of characteristics to randomly generate from. Owning one of the Laser Cats will give you exclusive access to “Tavern 77” where only these high-class mercenaries are granted entry.\n\nThe Laser Cats Collection consists of custom art from the Omniverse Team. We took a year and a half to focus solely on the art to ensure each NFT in the project is diverse and has an extremely high level of quality.\n\nIn the collection there are 11 species of Laser Cats that have multiple possibilities of traits that bring a large array of unique combinations to each individual Laser Cat Mercenary. Along with this there are rare gifted Laser cats and “One of One” Legendary Mercenary Laser Cats that can be randomly minted as well."
                        )} >
                        <Spritesheet_About_LCC />
                    </button>
                    <button id = "ourmissionbutton" onClick = {(event) => handlePopup(event, "Our Mission",
                        "The Omniverse team is a group of artists, engineers, and creatives that have been working in the video game and film/animation industry for over twenty years.\n\nWith the introduction of Web3 and the Metaverse we have a unique opportunity to create an immersive collection, and universe that is dedicated to and rightfully owned by its community. There has never been a more exciting time for Indie studios, when a community and developer are able to have a symbiotic connection working towards the same goal. Together we will create a new universe that will be without creative restrictions.\n\nWe have an exciting list of goals for The Omniverse and we will reveal them over the course of the next year."
                        )}>
                        <Spritesheet_About_OurMission />
                    </button>
                    <button id = "lorebutton" onClick = {(event) => handlePopup(event, "Lore",
                        "Laser Cats are a warrior race that have been genetically created on the planet Nero. They are hired as galactic mercenaries that roam the galaxy hunting down fugitives, criminals and galactic monsters. Some of these beings have cyber enhanced weaponry and genetically enhanced abilities that turn them into deadly fighters. Their planet was destroyed by the Cyber-Syndicate and search the universe for revenge versus their old adversaries.\n\nThe majority of the surviving Laser Cats found refuge in the city of Bymere on the planet Aris. Bymere is one of the three largest cities on Aris and thrives on its black markets economy. This is where the Laser Cats mercenary guild and base “Tavern 77” was founded.\n\nThe Cyber-Syndicate are a collective of extremely powerful self-aware androids. When the Cyber-Syndicate are not able to thrive on their own, they believe sacrifices must be made in order to save their people. Many planets have been ravaged due to the Cyber-Syndicates’ demand for rare resources or fertile land. As a proud and prideful race, the Laser Cats refused the Cyber-Syndicates’ demands and consequentially the vast majority of their species was wiped out. Left without adequate protection the Laser Cats home world of Nero was mined for resources and became a polluted and desolate wasteland.\n\nBymere’s black market is known across the Omniverse for being one of the most dangerous and most lucrative underworld economies. The Laser Cats exist within Bymere’s black market ecosystem providing many different services as mercenaries.  The Laser Cats along with the strong guilds give the city of Bymere high status and power.\n\nTavern 77 is a mystery to most outside of this world, but to people that are prominent in the black-market scene of Bymere understand that it’s the hub of the Laser Cats mercenary guild."
                        )}>
                        <Spritesheet_About_Lore />
                    </button>
                    <button id = "tavern77button" onClick = {(event) => handlePopup(event, "Tavern 77",
                        "Access to Tavern 77 will give owners of the Laser Cat collection the ability to claim future air drops of other unique NFTs within the Omniverse. Over time, we plan to develop Tavern 77 to become a hub where owners can see the latest updates from the Omniverse team, as well as access a range of more exclusive goods, entry to giveaways, and much more."
                        )}>
                        <Spritesheet_About_Tavern />
                    </button>
                    <button id = "ourstorybutton" onClick={(event) => handlePopup(event, "Our Story",
                        "Ominverse4 is a team of professionals that have designed digital products, video games, intellectual property and animation for over 20 years. We have worked for large Media and Entertainment companies that are consolidating distribution channels and intellectual property.\n\nAs digital creatives we have all been let go in staff reduction cycles, after toiling away on game development and animated projects for over 3 years. The explanation for the staff reductions was to bolster stock prices and executive bonuses, even though these companies already had record growth and profits. Because of this, we have made it our responsibility to do things differently. We will prioritize creative talent and retain them as employees rather than cast them aside to gain a surplus in profit. We believe that success is brought about with a team that can collaborate well together, which can only deepen with time.\n\nThe Omniverse4 wishes to control its own destiny and build an innovative and dedicated team that will specifically support our Web3 community and assist the growth of our universe.\n\nOur vision is to bring back the reverence for authentic and unique rather than recycled ideas. We want to inspire others to turn their dreams and ideas into a reality. We are pledged to putting all our energy towards making Laser Cats a success for our community."
                        )}>
                        <Spritesheet_About_OurStory />
                    </button>

                    <div className = "about-popup-container"
                        style={{ visibility: popup === false ? "hidden" : "visible"}}>
                        <div className="close">
                            <img onClick = {(event) => handlePopup(event)} />
                        </div>
                        <div className = "about-popup-bg">
                            {popupHeaderText == "Laser Cats Collection" ? <Spritesheet_About_Popup_LCC /> : 
                            popupHeaderText == "Our Mission" ? <Spritesheet_About_Popup_OurMission /> :
                            popupHeaderText == "Lore" ? <Spritesheet_About_Popup_Lore /> :
                            popupHeaderText == "Tavern 77" ? <Spritesheet_About_Popup_Tavern /> : 
                            popupHeaderText == "Our Story" ? <Spritesheet_About_Popup_OurStory /> : <div/>
                            }
                        </div>
                        <div className="about-popup">
                            <h1 className = {popup === true ? "about-info-header" : ""}
                                style={{color: popupHeaderText == "Laser Cats Collection" ? '#02f0d8' : 
                                        popupHeaderText == "Our Mission" ? '#feb43b' : 
                                        popupHeaderText == "Lore" ? '#6cff00' : 
                                        popupHeaderText == "Tavern 77" ? '#a400ff' : 
                                        popupHeaderText == "Our Story" ? '#ff01cb' : "#ffffff"
                                    }}
                            ><u>{popupHeaderText}</u></h1>
                            <p className = {popup === true ? "about-info" : ""}
                            >{popupText}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;