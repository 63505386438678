import { useState } from 'react';
import { sanitize } from '../../utils/miscellaneous';

const NewsletterForm = ( { status, message, onValidated }) => {

  const [ error, setError ] = useState(null);
  const [ email, setEmail ] = useState(null);

  /**
   * Handle form submit.
   *
   * @return {{value}|*|boolean|null}
   */
  const handleFormSubmit = () => {

    setError(null);

    if ( ! email ) {
      setError( 'Please enter a valid email address' );
      return null;
    }

    const isFormValidated = onValidated({ EMAIL: email });

    // On success return true
    return email && email.indexOf("@") > -1 && isFormValidated;
  }

  /**
   * Handle Input Key Event.
   *
   * @param event
   */
  const handleInputKeyEvent = ( event ) => {
    setError(null);
    // Number 13 is the "Enter" key on the keyboard
    if (event.keyCode === 13) {
      // Cancel the default action, if needed
      event.preventDefault();
      // Trigger the button element with a click
      handleFormSubmit();
    }
  }

  /**
   * Extract message from string.
   *
   * @param {String} message
   * @return {null|*}
   */
  const getMessage = (message) => {
    if ( !message ) {
      return null;
    }
    const result = message?.split('-') ?? null;
    if ( "0" !== result?.[0]?.trim() ) {
      return sanitize(message);
    }
    const formattedMessage = result?.[1]?.trim() ?? null;
    return formattedMessage ? sanitize( formattedMessage ) : null;
  }

  return (
    <div className="email-container">
        <div className="email-form-container">
          <span className="email-label">Get on the list</span><br/>
              <input 
                id="email-address" 
                className="email-input-text" 
                type="email" 
                placeholder="Email Address" 
                name="EMAIL" 
                onChange={(event) => setEmail(event?.target?.value ?? '')}
                onKeyUp={(event) => handleInputKeyEvent(event)}
                required="" 
              />
              <button className="email-submit" onClick={handleFormSubmit}>Click</button>
        <div className="email-status">
          {'error' === status || error ? (
            <div
              className="email-status-invalid"
              dangerouslySetInnerHTML={{ __html: error || getMessage( message ) }}
            />
          ) : null }
          {'success' === status && 'error' !== status && !error && (
            <div className="email-status-valid" dangerouslySetInnerHTML={{ __html: sanitize(message) }} />
          )}
        </div>
      </div>
    </div>
  );
}

export default NewsletterForm
